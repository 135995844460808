<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="a" viewBox="0 0 1000 379.08" width="55" height="25" fill="currentColor">
    <path class="b" d="M75.61,252.06c0,13.71-.74,17.04-3.71,23.71-4.45,8.15-14.45,14.08-24.45,14.08s-20.37-5.93-24.82-14.08c-3.34-6.67-3.71-10-3.71-23.71V80.18c0-17.41,1.11-21.86,5.93-28.52,6.67-8.89,15.19-12.6,28.16-12.6h58.16c28.15,0,47.79,6.3,62.97,20.37,16.3,15.19,25.93,38.9,25.93,62.98,0,20.74-7.04,41.86-18.89,56.31-14.45,17.78-35.19,26.3-62.61,26.3h-42.97v47.05Zm28.52-96.32c22.97,0,37.79-13.34,37.79-33.34s-13.71-34.08-34.82-34.08h-31.49v67.42h28.52Z"/>
    <path class="b" d="M388.63,256.51c0,19.26-11.11,32.23-27.41,32.23-12.97,0-21.86-7.04-27.41-21.49-13.7,17.04-24.82,22.6-44.82,22.6s-38.16-7.04-53.71-20.74c-21.48-18.89-32.23-44.82-32.23-77.79,0-30.01,8.89-52.6,28.53-71.87,16.3-16.3,35.56-24.82,56.68-24.82,19.27,0,30.75,5.56,46.31,21.49,5.93-14.45,14.08-20.37,27.04-20.37,15.19,0,27.04,12.22,27.04,27.78v132.99Zm-131.14-64.83c0,28.52,14.82,47.42,37.79,47.42s37.42-19.26,37.42-47.05-15.19-46.68-37.79-46.68-37.42,18.89-37.42,46.31Z"/>
    <path class="b" d="M520.51,121.66c7.41-19.26,14.81-25.93,28.89-25.93,15.19,0,27.78,12.22,27.78,26.3,0,6.67-.74,8.89-5.56,20.74l-82.61,197.82c-8.15,18.89-14.82,24.82-28.89,24.82s-27.04-11.85-27.04-25.93c0-6.3,.74-8.52,5.56-20.37l21.48-50.01-53.72-127.06c-4.81-11.11-5.55-14.08-5.55-20.37,0-14.08,12.6-25.93,27.41-25.93,13.71,0,20.75,6.3,28.53,25.56l31.86,80.76,31.86-80.39Z"/>
    <path class="b" d="M841.11,211.68c0,47.05-37.79,80.76-90.76,80.76s-91.13-33.71-91.13-80.76c0-26.67,10-44.82,34.45-61.12-17.41-16.3-23.34-28.52-23.34-48.16,0-41.12,32.6-69.64,80.01-69.64s79.65,28.52,79.65,69.64c0,19.63-5.93,31.86-23.34,48.16,24.45,16.3,34.45,34.45,34.45,61.12Zm-123.73-7.41c0,18.52,14.45,33.34,32.97,33.34s32.6-14.82,32.6-32.97-14.82-32.97-32.23-32.97c-18.52,0-33.34,14.45-33.34,32.6Zm7.78-98.54c0,14.08,11.11,25.19,25.19,25.19s24.82-11.11,24.82-25.19c0-13.34-11.11-24.82-24.45-24.82-14.44,0-25.56,11.11-25.56,24.82Z"/>
    <path class="b" d="M859.71,126.85c0-18.89,10.74-31.12,27.41-31.12,12.22,0,21.12,6.3,27.04,19.26,15.19-15.19,24.82-20.37,37.42-20.37,15.56,0,27.78,12.6,27.78,28.15,0,12.97-5.56,18.15-27.78,24.82-29.64,10-38.16,18.15-37.42,36.3v68.53c0,12.97-.37,16.3-3.71,22.6-4.07,8.15-13.7,13.71-23.34,13.71s-19.63-5.56-23.71-13.71c-3.33-6.3-3.7-9.26-3.7-22.6V126.85Z"/>
    <path class="b" d="M644.61,252.43c0,12.97-.37,16.3-3.71,22.6-4.07,8.15-13.7,13.71-23.34,13.71s-19.63-5.56-23.71-13.71c-3.33-6.3-3.7-9.26-3.7-22.6V50.54c0-12.97,.37-16.3,3.7-22.23,4.08-8.15,13.71-13.71,23.71-13.71s19.27,5.56,23.34,13.34c3.34,6.3,3.71,9.26,3.71,22.6V252.43Z"/>
  </svg>
</template>

<script setup>
</script>
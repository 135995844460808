<!--Here for updating the project? Please check the section How do I update the project? on the Readme-->
<template>
  <Modal v-if="isVisible()" :price="price" :username="username" @toggleModal="toggleModal()"></Modal>
</template>
<!--<style scoped src="./assets/css/app.css"></style>-->
<script>
import Modal from './components/modalComponent.vue';

export default {
  name: 'App',
  components: {
    'Modal': Modal,
  },
  data() {
    return {
      isModalVisible: false,
      price: null,
      username: null,
      newPrice: null,
      showModal: false
    };
  },
  methods: {
    isVisible() {
      if (this.$store?.state?.isModal === 'undefined') {
        return false;
      } else {
        return this.$store?.state?.isModal
      } // state variable (true,false) from store.js
    },
    toggleModal() {
      this.$store.commit('toggleModal'); // calling toggleModal function from store.js
    },
  },
  watch:{
    price:function(newPrice) {
      this.price = newPrice;
    },
  },
  beforeUpdate() {
    this.price = document.plCalcPrice ? document.plCalcPrice.Price : 50;
    if(document.plCalcUsername) {
      this.username = document.plCalcUsername.Username
    }
    else if(document.plGetUsername) {
      this.username = document.plGetUsername.Username
    }
    else {
      this.username = 'SANDBOX'
    }
  },
  updated() {
    this.price = document.plCalcPrice ? document.plCalcPrice.Price : null;
  },
}
</script>
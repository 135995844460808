<template>
  <svg id="Group_522" data-name="Group 522" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="35.293" height="43.296" viewBox="0 0 35.293 43.296">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_41" data-name="Rectangle 41" width="35.293" height="43.296" fill="#c481d1"/>
      </clipPath>
    </defs>
    <g id="Group_524" data-name="Group 524" clip-path="url(#clip-path)">
      <path id="Path_64" data-name="Path 64" d="M1.607,29.313A1.285,1.285,0,0,1,.943,26.83l2.5-.67c.677-1.318,6.057-11.8,6.968-13.188,2.407-3.672,8.012-2.477,6.71,2.379L16.359,18.2,28.7,14.89a3.736,3.736,0,0,1,4.2,5.474c-.955,1.655-2.5,1.8-4.334,2.3a3.741,3.741,0,0,1-1.306,5.3,3.735,3.735,0,0,1-.563,4.917,3.739,3.739,0,0,1-1.9,6l-8.529,2.285c-.452.121-2.846-.085-3.588.113L5.34,43.244a1.285,1.285,0,0,1-.663-2.484L12.01,38.8a22.236,22.236,0,0,1,3.755-.158L24.127,36.4a1.164,1.164,0,0,0-.6-2.247l-5.5,1.474a1.285,1.285,0,0,1-.663-2.484L24.5,31.228a1.164,1.164,0,0,0-.6-2.247l-5.5,1.473a1.285,1.285,0,0,1-.663-2.484l8-2.145a1.164,1.164,0,0,0-.6-2.247l-5.5,1.474a1.285,1.285,0,0,1-.663-2.484l11-2.946a1.164,1.164,0,0,0-.6-2.247L14.887,21.253a1.287,1.287,0,0,1-1.587-1.57l1.339-5c.243-.906.167-1.4-.666-1.275a1.958,1.958,0,0,0-1.393.942c-1.656,2.836-7.09,13.453-7.105,13.483a1.286,1.286,0,0,1-.814.659l-3.053.818ZM13.165,4.283A1.282,1.282,0,0,1,13.717,2.4L17.911.166a1.282,1.282,0,0,1,1.207,2.262l-1.4.749L31.193,6.789,30.352,5.44a1.285,1.285,0,0,1,2.182-1.357L35.08,8.167a1.282,1.282,0,0,1-.551,1.881l-4.194,2.237a1.282,1.282,0,0,1-1.207-2.262l1.4-.749L17.052,5.661l.841,1.349a1.285,1.285,0,0,1-2.182,1.357L13.166,4.283Z" transform="translate(0 0)" fill="#c481d1" fill-rule="evenodd"/>
    </g>
  </svg>
</template>
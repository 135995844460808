<template>
  <div class="payl8r-modal-backdrop"/>
  <div class="payl8r-modal-area">
    <div class="payl8r-modal">
      <div class="payl8r-header-area">
          <button type="button" class="payl8r-btn-close" @click="toggleModal"><span class="close-wording">Close</span>
            <CloseIcon class="close-icon"/>
          </button>
        <div>
          <div class="payl8r-header-contents">
            <div class="payl8r-logo">
              <img src="https://assets.payl8r.com/images/Payl8r-logo-white.png" alt="Payl8r black logo" id="logo">
            </div>
            <div class="title-div"> Spread the cost. </div>
            <TitleModal :installmentsInfo="installmentsInfo" ></TitleModal>
          </div>
        </div>
      </div>
      <Info></Info>
      <RepayMonth :installmentsInfo="installmentsInfo" :repExample="repExample" :deposit="deposit" :amount="price" ></RepayMonth>
      <Requirements/>
      <Footer :retailerName="retailerName" :broker-frn="brokerFrn" :firm-reference-number="firmReferenceNumber"/>
    </div>
  </div>
</template>
<script>
import titleModal from "./titleModal";
import RepayMonth from "./repayMonth";
import Info from "./infoComponent";
import Requirements from "./requirementsComponent";
import Footer from "./footerComponent";
import JQuery from 'jquery';
import CloseIcon from "@/components/CloseIcon.vue";

export default {
  components: {
    CloseIcon,
    'TitleModal': titleModal,
    'RepayMonth': RepayMonth,
    'Info': Info,
    'Requirements': Requirements,
    'Footer': Footer,
  },
  props: {
    price: Number,
    username: String,
    isModalVisible: Boolean,
  },
  data() {
    return {
      installmentsInfo:{},
      repExample:{},
      deposit:null,
      retailerName: '',
      brokerFrn: null,
      firmReferenceNumber: null
    }
  },
  methods: {
    toggleModal() {
      this.$emit('toggleModal');
    },
    fetchData(total, username) {
      const vue = this;
      JQuery.ajax({
        url: 'https://payl8r.com/get-product-page-calculator?username=' + username + '&total=' + total + '&includeRepresentativeExample=true',
      }).done(function(response) {
        let data = JSON.parse(response)
        if (data.status === 200) {
          vue.installmentsInfo = data.data;
          vue.repExample = data.repExample
          vue.retailerName = data.retailerName
          vue.brokerFrn = data.brokerFrn
          vue.firmReferenceNumber = data.firmReferenceNumber
          // Obtain minimumDepositRate
          let termsObject = data.data;
          let firstTerm = Object.keys(termsObject)[0];
          let accessTermObject = data.data[firstTerm];
          vue.deposit = Number(total) * Number(accessTermObject.minimumDepositRate);
        }
      });
    },
  },watch:{
    price:function() {
      this.fetchedData = this.fetchData(this.price, this.username);
  },
},
  async beforeMount() {
    this.fetchedData = await this.fetchData(this.price, this.username);
  },

};
</script>

<template>
  <div id="payl8r-repayment-calculator-content">
    <div class="payl8r-repayment-calculator-header">
      Repayment plan calculator
    </div>
    <ul class="repay-months-wrapper">
      <li v-for="(value, key) in installmentsInfo" :key="key" class="repay-month-boxes">
        <RepayMonthBox :month='key' :repayment='value' :class='key'></RepayMonthBox>
      </li>
    </ul>

    <RepayGeneralInfo :deposit-amount="deposit" :amount="amount"></RepayGeneralInfo>

    <p class="repay-month-info">*Rates, totals and monthly payment amounts are indicative and are subject to status and
      assume that you pay a deposit of &pound;{{(Math.round(deposit * 100) / 100).toFixed(2)}} on checkout.</p>

    <p class="payl8r-info-box">Subject to credit and affordability assessments, application fees may apply. Rep Example:
      If you borrow <span v-html="repExample.loanAmount"></span> over {{repExample.termInMonths}} months at a fixed monthly interest rate of {{repExample.interest}}% and an annual rate of {{repExample.annualRate}}% and representative
      annual percentage rate of {{repExample.APR}}% You will pay: Monthly payment: <span v-html="repExample.monthlyPaymentAmount"></span>. Total repayment: <span v-html="repExample.totalAmountToPay"></span> Total charge for credit: <span v-html="repExample.totalCredit"></span>.
      Missed payments may affect your credit file, future borrowing and incur fees.</p>
  </div>
</template>
<script>
import RepayMonthBox from "@/components/repayMonthBox";
import RepayGeneralInfo from "@/components/repayGeneralInfo.vue";

export default {
  components: {
    RepayGeneralInfo,
    'RepayMonthBox': RepayMonthBox,
  },
  props: {
    installmentsInfo: Object,
    repExample: Object,
    deposit: Number,
    amount: Number
  },
}
</script>

export default {
    state: {
        isModal: false
    },
    getters: {},
    mutations: {
        toggleModal (state) {
            state.isModal = !state.isModal;
        },
    },
    actions: {}
}
import { createApp } from 'vue'
import Main from './Main.vue'
import Modal from './Modal.vue'
import store from './store/store.js'
import {createStore} from "vuex";

// Create a div which is placed at the end of the body tag (to avoid z-index issues & avoid some css issues)
let modal_div = document.createElement('div');
modal_div.id = 'pl-calc-modal';
let body = document.querySelector('body');
body.appendChild(modal_div);

let cssId = 'payl8r-calc-css';  // you could encode the css path itself to generate id..
if (!document.getElementById(cssId))
{
    let head  = document.getElementsByTagName('head')[0];
    let link  = document.createElement('link');
    link.id   = cssId;
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://assets.payl8r.com/css/pl-calc-css/pl-calc-default-new.css';
    link.media = 'all';
    head.appendChild(link);
}


const modalHandler = createStore(store);

// Split into 2 apps, one for the "Spread the cost section" another for the modal
createApp(Main).use(modalHandler).mount('#pl-calculator-light-app');

createApp(Modal).use(modalHandler).mount('#pl-calc-modal');

// Additional styling added to calculator if Pl-calculator-light-app div size is below 385px.

if(document.getElementById('pl-calculator-light-app').clientWidth < 321) {
    // document.querySelector('#pl-calculator-light-app > .spread').style = 'margin-top: 2px;';
    document.querySelector('#pl-calculator-light-app > .spread > .payl8r-image-box').style = 'width: 64px;';
    document.querySelector('#pl-calculator-light-app > .spread > .payl8r-image-box > .show-modal-logo').style = 'margin-top: 2px;'
    document.querySelector('#pl-calculator-light-app > .spread > .payl8r-text').style = 'text-transform: lowercase;font-size: 10px !important;'
}
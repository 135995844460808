<template>
  <div class="title-modal-spread-cost">Apply at checkout and pay over <span v-if="Object.keys(installmentsInfo)[0] == 0">1</span> <span v-else >{{Object.keys(installmentsInfo)[0]}}</span>,
    {{Object.keys(installmentsInfo)[1]}}, {{Object.keys(installmentsInfo)[2]}} or even
    {{Object.keys(installmentsInfo)[3]}} months. <span v-if="Object.keys(this.installmentsInfo).length > 0 && Object.values(this.installmentsInfo)[0].interest === 0">Interest free options available.</span>
  </div>
</template>
<script>

export default {
  props: {
     installmentsInfo: Object,
  }
};
</script>
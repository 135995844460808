<!--Here for updating the project? Please check the section How do I update the project? on the Readme-->
<template>
  <div class="spread">
<!--        <span class="show-modal-logo" @click="toggleModal()">Payl8r </span>-->
<!--        <span id="Payl8r-logo-text">Spread the cost </span>-->
<!--        <span class="show-modal" @click="toggleModal()">view options</span>-->

    <div class="payl8r-text">
      <div id="Payl8r-logo-text"> Spread the cost with </div>
      <div class="payl8r-image-box">
        <Payl8rLogo @click="toggleModal()"/>
      </div>
      <div class="show-modal" @click="toggleModal()">view options</div>
    </div>
  </div>
</template>
<!--<style scoped src="./assets/css/app.css"></style>-->
<script>
import Payl8rLogo from "@/components/Payl8rLogo.vue";
import JQuery from "jquery";

export default {
  name: 'App',
  components: {Payl8rLogo},
  data() {
    return {
      username: null,
    }
  },
  methods: {
    toggleModal() {
      this.$store.commit('toggleModal'); // calling toggleModal function from store.js
    },
    postHostname(hostname, username) {
      JQuery.ajax({
        url: 'https://payl8r.com/post-retailer-hostname?hostname=' + hostname + '&username=' + username,
      })
    }
  },

  beforeMount() {
    if(document.plCalcUsername) {
      this.username = document.plCalcUsername.Username
    }
    else if(document.plGetUsername) {
      this.username = document.plGetUsername.Username
    }
    else {
      this.username = 'SANDBOX'
    }

    let hostname = window.location.hostname
    this.postHostname(hostname, this.username)

  }
}
</script>
<template>
    <div class="payl8r-info-modal">
      <div class="payl8r-header-list">
        <div class="payl8r-info-modal-contents">
          <div class="payl8r-info-modal-image-container">
            <BasketIcon/>
          </div>
          <p>
            Select Payl8r<br>at the checkout
          </p>
        </div>
        <div class="payl8r-info-modal-contents">
          <div class="payl8r-info-modal-image-container">
            <SpreadTheCostIcon/>
          </div>
          <p>
            Choose how long<br>to spread the cost
          </p>
        </div>
        <div class="payl8r-info-modal-contents">
          <div class="payl8r-info-modal-image-container">
            <SunbedIcon/>
          </div>
          <p>
            Pay back over<br>time automatically
          </p>
        </div>
      </div>
    </div>
</template>
<script>

// import info from '../assets/img/info.png';
import BasketIcon from "@/components/BasketIcon.vue";
import SpreadTheCostIcon from "@/components/SpreadTheCostIcon.vue";
import SunbedIcon from "@/components/SunbedIcon.vue";

export default {
  components: {SunbedIcon, SpreadTheCostIcon, BasketIcon}
};
</script>
<template>
  <div class="payl8r-requirements-modal">
    <div class="payl8r-requirements-modal-area">
      <div class="payl8r-requirements-title-area">
        What you need to get started
      </div>
      <div class="payl8r-requirements-lists">
        <div class="payl8r-requirements-list">
          <img class="payl8r-tick" src="https://assets.payl8r.com/images/tick-purple.png" alt="sunbed">
<!--          <div class="payl8r-requirements"><img class="payl8r-requirements-tick" src="https://assets.payl8r.com/images/pl-calc-images/payl8r_Tick.png" alt="spread cost image"></div>-->
          <p id="payl8r-requirements-text-deposit">Your deposit</p>
        </div>
        <div class="payl8r-requirements-list">
          <img class="payl8r-tick" src="https://assets.payl8r.com/images/tick-purple.png" alt="sunbed">
          <!--          <div><img class="payl8r-requirements-tick" src="https://assets.payl8r.com/images/pl-calc-images/payl8r_Tick.png" alt="spread cost image"></div>-->
          <p>Be aged 18 or over</p>
        </div>
        <div class="payl8r-requirements-list">
          <img class="payl8r-tick" src="https://assets.payl8r.com/images/tick-purple.png" alt="sunbed">
          <!--          <div><img class="payl8r-requirements-tick" src="https://assets.payl8r.com/images/pl-calc-images/payl8r_Tick.png" alt="spread cost image"></div>-->
          <p>Be a UK resident</p>
        </div>
        <div class="payl8r-requirements-list">
          <img class="payl8r-tick" src="https://assets.payl8r.com/images/tick-purple.png" alt="sunbed">
          <!--          <div><img class="payl8r-requirements-tick" src="https://assets.payl8r.com/images/pl-calc-images/payl8r_Tick.png" alt="spread cost image"></div>-->
          <p class="payl8r-requirements-text">Access to online banking</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {

  data() {
    return {
    };
  },
  methods: {}
};
</script>
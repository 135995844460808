<template>
  <div class="payl8r-modal-footer">
    <p class="payl8r-footer-text" v-if="!brokerFrn">
      {{ retailerName }} is a Introducer Appointed Representative of Social Money Ltd t/a Payl8r, a company registered
      in England under company number 08054296, and is authorised and regulated by the Financial Conduct Authority,
      and is entered on the Financial Services Register under reference number 675283. Registered with the Information Commissioner’s
      Office under reference number ZA026178.
    </p>
    <p class="payl8r-footer-text" v-else>
      {{ retailerName }} is a company registered in (England, Scotland etc) under company number {{ firmReferenceNumber }},
      and is authorised and regulated by the Financial Conduct Authority (FCA), and is entered on the Financial Services (FS)
      Register under reference number {{ brokerFrn }}: {{ retailerName }} is a Credit Broker to Social Money Limited t/a Payl8r.
      Social Money Limited is a company registered in England under company number 08054296 and is authorised and regulated by the FCA,
      and is entered on the FS Register under reference 675283. Registered with the Information Commissioner's Office under reference number ZA026178.
    </p>
  </div>
</template>
<script>


export default {
  props: {
    retailerName: String,
    brokerFrn: String,
    firmReferenceNumber: Number
  }
};
</script>
<template>
  <svg id="Group_525" data-name="Group 525" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="44.255" height="40.606" viewBox="0 0 44.255 40.606">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_43" data-name="Rectangle 43" width="44.255" height="40.606" fill="#c481d1"/>
      </clipPath>
    </defs>
    <g id="Group_526" data-name="Group 526" clip-path="url(#clip-path)">
      <path id="Path_68" data-name="Path 68" d="M44.255,19.3a1.583,1.583,0,0,1-1.589,1.589h-.294l-6.3,18.655A1.574,1.574,0,0,1,34.6,40.606H9.945a1.575,1.575,0,0,1-1.471-1.059L1.942,20.892H1.589a1.589,1.589,0,0,1,0-3.178H14.418L17.3,9.887a2.192,2.192,0,0,1-.412-2.06l2.354-6.415a2.084,2.084,0,0,1,2-1.413H23.01a2.084,2.084,0,0,1,2,1.412l2.354,6.415a2.189,2.189,0,0,1-.412,2.06l2.884,7.827H42.666A1.583,1.583,0,0,1,44.255,19.3Zm-38.9,1.589,1.413,4.06h6l-.706-4.06Zm3.59,10.3H13.83l-.706-4.119H7.474Zm2.178,6.238h3.825l-.706-4.119H9.652Zm3.06-16.536.706,4.06h6.179v-4.06Zm1.825,10.3h5.061V27.071H15.3Zm.353,2.119.765,4.119h3.943V33.309Zm.294-15.595H27.6l-2.59-7.12A2.047,2.047,0,0,1,23.364,9.24L22.128,5.827,20.893,9.24a2.047,2.047,0,0,1-1.648,1.354Zm12.711,7.238.706-4.06H23.187v4.06Zm-.412,2.119H23.187v4.119h5.061ZM23.187,37.428H27.13l.765-4.119H23.187ZM34.84,33.309H30.014l-.706,4.119h4.119Zm2.06-6.238H31.132l-.765,4.119h5.179Zm2.118-6.179H32.191l-.706,4.06h6.179Z" fill="#c481d1"/>
    </g>
  </svg>
</template>
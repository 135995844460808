<template>
  <div class="payl8r-general-repay-info">
    <div class="payl8r-general-repay-info-contents">
      <div style="width: 50%">
        <div class="payl8r-repay-text">Purchase amount</div>
        <div class="payl8r-repay-amount">£{{Number(amount).toFixed(2)}}</div>
      </div>
      <div style="width: 50%">
        <div class="payl8r-repay-text">Based on a deposit of</div>
        <div class="payl8r-repay-amount">£{{Number(depositAmount).toFixed(2)}}</div>
      </div>
    </div>
  </div>
</template>
<script>

// import info from '../assets/img/info.png';
export default {
  name: 'repay-genera-info',
  props: {
    amount: Number,
    depositAmount: Number
  }
};
</script>
<template>
  <div class="repay-month-box">
    <span v-if="month == 1" class="repay-month-text" ><b>&pound;{{ repayment.monthlyPaymentAmount }}*</b> for 1 month</span>
    <span v-else class="repay-month-text" ><b>&pound;{{ repayment.monthlyPaymentAmount }}* per month</b> for {{month}} months</span>
  </div>
</template>
<script>
export default {
  name: "repayMonthBox",
  props: {
    month: String,
    repayment: Object,
  },
}
</script>

<style scoped>

</style>